/**
 ** Print stylesheet for Resume
 ** Copyright 2008-2024 Adam J. Jolicoeur
 ** https://www.adamjolicoeur.com
**/
body {
  font: 12pt "Times New Roman", Times, serif;
  line-height: 1.3;
  background: #fff !important;
  color: #000;
}

header,
footer,
aside,
nav,
form,
iframe,
.menu,
.hero,
.adslot {
  display: none;
}

#navigation,
#footer {
  display: none !important;
}

main,
content {
  display: block !important;
  max-width: 8.5in !important;
  width: 100%;
  margin: 0;
  float: none;
}

.row {
  display: block !important;
}

.col-md-6 {
  width: 100% !important;
}

.mt-4, .mt-3, .mt-2 {
  margin-top: 2pt !important;
}

.mb-4, .mb-3, .mb-2 {
  margin-bottom: 2pt !important;
}

.my-4, .my-3, .my-2 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.py-4, .py-3, .py-2 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pb-4, .pb-3, .pb-2 {
  padding-bottom: 2pt !important;
}

* {
  background-image: none !important;
}

img,
svg {
  display: none !important;
}

table,
img,
svg {
  break-inside: avoid;
}

.text-bg-light {
  background-color: transparent !important;
}

.row-badges {
  display: none !important;
}

.hide-on-print {
  display: none !important;
}

.print {
  display: block !important;
}

h1,
.h1 {
  font-size: 22pt;
  break-before: always;
}

h2,
.h2 {
  font-size: 19pt;
  margin-top: 16px;
  margin-bottom: 8px;
}

h3,
.h3 {
  font-size: 17pt;
  margin-top: 16px;
  margin-bottom: 8px;
}

h4,
.h4 {
  font-size: 14pt;
  margin-top: 16px;
  margin-bottom: 8px;
}

h5,
.h5 {
  font-size: 12pt;
  margin-top: 16px;
  margin-bottom: 8px;
}

p,
.paragraph {
  font-size: 12pt;
  margin-bottom: 8px;
}

/* Defining all page breaks */
a {
  page-break-inside: avoid;
}

blockquote {
  page-break-inside: avoid;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  page-break-after: avoid;
  page-break-inside: avoid;
}

img {
  page-break-inside: avoid;
  page-break-after: avoid;
}

table,
pre {
  page-break-inside: avoid;
}

ul,
ol,
dl {
  page-break-before: avoid;
}

/* Displaying link color and link behavior */
a:link,
a:visited,
a {
  background: transparent;
  color: #520;
  font-weight: bold;
  text-decoration: underline;
  text-align: left;
}

a {
  page-break-inside: avoid;
}

a[href^=http]:after {
  content: " < " attr(href) "> ";
}

a:after > img {
  content: "";
}

article a[href^="#"]:after {
  content: "";
}

a:not(:local-link):after {
  content: " < " attr(href) "> ";
}
